import React from 'react'
import NumberFormat from 'react-number-format';

const Star = props => (
	<span className={props.full}></span>
);

const Stars = props => (
	<span>
		{[...Array(5)].map(function(e, i) {
			const full = (i+1) <= props.rating ? 'icon icon-star-full' : 'icon icon-star';
			return (
				<Star full={full} key={i}/>
			)
		})}
	</span>
);

export default class Product extends React.Component {

	highlight = () => {
		if( this.props.view == 'map' && this.props.showMap == true ) {
			this.props.highlightItem(this.props.product.id)
		}
		return false
	};

	render() {

		return (
			<div onMouseEnter={this.highlight} onMouseLeave={() => this.props.highlightItem(0)} className={this.props.highlighted === this.props.product.id ? 'cell product-grid__product highlighted'  : 'cell product-grid__product' } itemScope itemType="http://schema.org/Product" id={`experience-` + this.props.product.id}>

				<a itemProp="url" className={this.props.promoted || this.props.product.promoted ? "product-grid__product--link promoted" : "product-grid__product--link"} href={ this.props.path + '/' + this.props.product.slug}>

					<div className="product-grid__product--image-wrapper" name={this.props.product.name}>
						<div className="image-container">
							<div itemProp="image" className={this.props.product.sold_out ? 'product-grid__product--image lazyload sold-out' : 'product-grid__product--image lazyload'} data-bg={`${this.props.cdnPath}/${this.props.product.image}?w=400&h=300&fit=crop&auto=compress`}>
								<div className="product-grid__product--image-overlay"></div>
							</div>

							{this.props.product.badge?
								<div className={`badges badge-` + this.props.product.badge}></div>
							: null
							}	

							{ this.props.product.capacity || this.props.product.minimum ?
								<div className="product-grid__product--pill white">
									<div className="align-content vertical center">
										<span className="icon-group"></span>
										{ this.props.product.capacity == this.props.product.minimum ?
											<p>{this.props.product.capacity}</p> :
										  this.props.product.minimum > 0 && this.props.product.capacity == 0 ?
											<p>{this.props.product.minimum} + </p> :
										  this.props.product.capacity > 0 ? 
											<p>{this.props.product.minimum} - {this.props.product.capacity}</p> 
										: null
										}
									</div>
								</div>
							: null
							}
						</div>


						<div className="product-grid__product--name">
							<div>
								{this.props.promoted || this.props.product.promoted ?
									<div className="sponsored"><p>Sponsored</p></div>
								: null }

								<span className="product-grid__product--heading">
									<span itemProp="name">{this.props.product.name}</span>
									{this.props.product.location.length > 0 && !this.props.product.virtual? ( <> in {this.props.product.location}</> ) : null}
								</span>
								
								{ this.props.product.reviews > 0 ?
									<div className="align-content">
										<p className="product-grid__product--reviews" itemProp="aggregateRating" itemScope itemType="http://schema.org/AggregateRating"><Stars itemProp="ratingValue" rating={this.props.product.rating} /> <span itemProp="reviewCount">({this.props.product.reviews})</span><meta itemProp="ratingValue" content={this.props.product.rating} /></p>
									</div>
								: null }
								
								{this.props.product.sold_out && <p className="product-grid__product--banner sold-out"><span>2024 DATES COMING SOON</span></p> }
								{!this.props.product.sold_out && <p className="product-grid__product--supplier">{this.props.product.venue_ident != null ? this.props.product.venue_ident : this.props.product.supplier.name}</p> }
							</div>

							{ this.props.product.price != null && this.props.product.price > 0 ?
								<div className="listprice-style" itemProp="offers" itemScope itemType="http://schema.org/AggregateOffer"><p className="product-grid__product--price">{this.props.product.price_from ? 'From ' : null}<NumberFormat itemProp="lowPrice" value={this.props.product.price} displayType={'text'} thousandSeparator={true} prefix={'£'} decimalScale={2} fixedDecimalScale={true} /> {this.props.product.fixed_price ? 'Per group' : 'per person'}</p></div>
								: null
							}
							
							
							{this.props.mobile && this.props.showMap && this.props.view == 'map' ?
								<div className="product-grid__product--mobile"><span className="icon icon-mobile-car primary"></span> Mobile Experience</div>
							: null
							}
						</div>

						<div className="product-grid__product--summary"><span itemProp="description">{this.props.product.summary}</span></div>
						<div className="view-btn"><button className="button primary small" href={'/products/' + this.props.product.slug}>View</button></div>
					</div>
				</a>
			</div>
		)
	}
}
